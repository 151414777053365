body {
    .pace {
        .pace-activity {
            display: block;
            position: fixed;
            z-index: 2000;
            top: 35px;
            right: 5px;
            width: 20px;
            height: 20px;
            border: solid 3px transparent;
            border-top-color: #0d6efd;
            border-left-color: #0d6efd;
            border-radius: 10px;
        }
    }
}

.ck-editor__editable {
    min-height: 200px !important;
}


/* ------------------- invoice css start------------------  */

.patient-invoice .patient-invoice-header-left .avatar {
    height: 100%;
}

@media print {
    .patient-invoice {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    .patient-invoice .patient-invoice-header-middle {
        width: 200px !important;
    }

    .patient-invoice .padding-none {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }

    .patient-invoice .print-mx-none {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .patient-invoice .avatar img {
        width: 200px !important;
        height: 100%;
        object-fit: fill;
    }

    .patient-invoice .avatar p {
        font-size: 12px !important;
    }

    .patient-invoice .system-generate-invoice {
        font-size: 12px !important;
    }

    .patient-invoice .invoice-footer {
        display: none !important;
    }
}


/* ------------------------invoice css end -----------------  */



/* custom css  */

.dynamic-description p {
    font-size: 18px;
  }
  .dynamic-description {
    width: 100%;
  }
  
  .dynamic-description ul li {
    position: relative;
    font-size: 18px;
  }
  .dynamic-description ul li:before {
    content: "";
    position: absolute;
  }
  
  .dynamic-description a:hover {
    text-decoration: underline;
  }

  .dynamic-description blockquote p:nth-child(1) {
    font-size: 26px;
    /* line-height: 40px; */
    /* width: 100%; */
  }
  
  .dynamic-description blockquote p:nth-child(2) {
    font-size: 18px;
  }
  .dynamic-description blockquote p:nth-child(1)::before,
  dynamic-description blockquote p:nth-child(1)::after {
    content: open-quote; /* Modern browsers use this */
  }
  
  .dynamic-description blockquote p:nth-child(1)::after {
    content: close-quote; /* Modern browsers use this */
  }

  .dynamic-description table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-collapse: collapse;
    border: 2px solid #dee2e6;
}

.dynamic-description table thead {
    vertical-align: bottom;
    color: white;
    border-bottom: 2px solid #dee2e6;
}

.dynamic-description table th,
.dynamic-description table td {
    padding: 0.75rem;
    border: 1px solid #dee2e6;
}

.dynamic-description table th {
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.dynamic-description table tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.dynamic-description table tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.dynamic-description table.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}



