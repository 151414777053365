@media print {
    .dashboard-layout
    {
        width: 100%;
       
        margin: 0;
        .navbar
        {
            display: none;
            margin: 0;
            width: 0;
        }
        .sidebar-wrapper
        {
            display: none;
            margin: 0;
            width: 0;
        }
        .page-content
        {
            width: 100%!important;
            margin: 0 auto;
            padding: 0;
        }
    }
}