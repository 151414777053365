.font-weight {
  font-weight: 300 !important;
}

@media print {
  .profit-loss-search {
    visibility: hidden;
  }
  .statement-financial {
    visibility: hidden;
  }
}
