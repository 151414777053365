.card-box
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.action
{
    
}